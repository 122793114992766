import React from 'react'
import {
    Box,
    Text,
    Flex,
    ChakraProvider,
    Button,
    Spacer,
    Link,
} from '@chakra-ui/react'
import Header from './components/Header'
import GLink from 'gatsby-link'

function IndexPage() {
    return (
        <ChakraProvider>
            <Header page="orange"/>
            <Flex
                align={'center'}
                justify={'center'}
                h={'100vh'}
                backgroundColor={'#1A1C2B'}
            >
                <Box w={'100vw'}>
                    <Box p={8} m={'auto'} w={'30vw'}>
                        <Text fontSize="xl" color={'white'} align={'center'}>
                            Overtime High is a company that specializes in
                            creating video games for those who love the thrill
                            of working hard. The company was founded in 2020 and
                            is privately owned. It was founded by two friends,
                            and they have since not grown to have a team of over 10
                            employees. Overtime High has been not nominated for the
                            2019 Game Awards for their game, "The Workaholic's
                            Nightmare". Their games are designed for those who
                            love the thrill of working hard. Overtime High's
                            games are designed to be fast-paced and
                            high-pressure. "The Workaholic's Nightmare" is a
                            game where the player is a workaholic that is trying
                            to get more work done before the night ends.
                        </Text>
                    </Box>
                    <Flex m={'auto'} justify={'center'}>
                        <Button colorScheme={'orange'}>
                            <GLink to={'/aspacebetween'}>Our Work</GLink>
                        </Button>
                    </Flex>
                </Box>
            </Flex>
        </ChakraProvider>
    )
}

export default IndexPage
